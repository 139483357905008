.popover {
  /* This block shouldn't be necessary */
  --panel-width: 42rem;
  --panel-offset: calc(var(--panel-width) - 2rem);
  left: calc(var(--panel-offset) / -2);
  width: var(--panel-width);
  /* --------------------------- */
  box-shadow: 0px 3px 14px rgba(23, 25, 33, 0.04), 0px 5px 5px rgba(23, 25, 33, 0.02);
}
.popover:before {
  content: "";
  @apply absolute border-gray-200 bg-white border w-2.5 h-2.5;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.popover[data-popper-placement=top], .popover[data-popper-placement=top-start], .popover[data-popper-placement=top-end] {
  margin-bottom: var(--core-popover-offset, 1rem);
}
.popover[data-popper-placement=top]:before, .popover[data-popper-placement=top-start]:before, .popover[data-popper-placement=top-end]:before {
  bottom: -6px;
  left: calc(50% - 5px);
  border-style: none solid solid none;
}
.popover[data-popper-placement=bottom], .popover[data-popper-placement=bottom-start], .popover[data-popper-placement=bottom-end] {
  margin-top: var(--core-popover-offset, 1rem);
}
.popover[data-popper-placement=bottom]:before, .popover[data-popper-placement=bottom-start]:before, .popover[data-popper-placement=bottom-end]:before {
  top: -6px;
  left: calc(50% - 5px);
  border-style: solid none none solid;
}
.popover[data-popper-placement=right], .popover[data-popper-placement=right-start], .popover[data-popper-placement=right-end] {
  margin-left: var(--core-popover-offset, 1rem);
}
.popover[data-popper-placement=right]:before, .popover[data-popper-placement=right-start]:before, .popover[data-popper-placement=right-end]:before {
  top: calc(50% - 5px);
  left: -6px;
  border-style: none none solid solid;
}
.popover[data-popper-placement=left], .popover[data-popper-placement=left-start], .popover[data-popper-placement=left-end] {
  margin-right: var(--core-popover-offset, 1rem);
}
.popover[data-popper-placement=left]:before, .popover[data-popper-placement=left-start]:before, .popover[data-popper-placement=left-end]:before {
  top: calc(50% - 5px);
  right: -6px;
  border-style: solid solid none none;
}

.popover h2 {
  font-weight: bold;
  font-size: 1.5em;
}
